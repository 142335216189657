<template>
  <div class="details">
    <div class="det_center" v-if="this.viewData!==null">
      <nav>
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
          <!-- <el-breadcrumb-item :to="{ path: '/course' }">课程案例</el-breadcrumb-item> -->
          <el-breadcrumb-item :to="{ path: '/course' }" v-if="showonw==0">课程案例</el-breadcrumb-item>
          <el-breadcrumb-item :to="{ name: `${showp}`}" v-else>{{shown}}</el-breadcrumb-item>
          <el-breadcrumb-item :to="{ path: '/introduce', query: {id: this.$route.query.id ,cardsite: showonw},}">{{viewData.title}}</el-breadcrumb-item>
          <el-breadcrumb-item>{{breadtitle}}</el-breadcrumb-item>
        </el-breadcrumb>
      </nav>
      <div class="det_header">
        <div class="det_h_center">
          <div class="h_top">
            <p>{{viewData.title}}</p>
          </div>
          <div class="h_ss">
            <p class="ss_left">
              <el-tooltip class="item" effect="dark" :content="viewData.periodName" placement="top">
                <span>{{viewData.periodName}}</span>
              </el-tooltip>
              <el-tooltip class="item" effect="dark" :content="viewData.gradeName" placement="top">
                <span>{{viewData.gradeName}}</span>
              </el-tooltip>
              <el-tooltip class="item" effect="dark" :content="viewData.subjectName" placement="top">
                <span>{{viewData.subjectName}}</span>
              </el-tooltip>
              <!-- <span>{{viewData.periodName}}</span>
              <span>{{viewData.gradeName}}</span>
              <span>{{viewData.subjectName}}</span>-->
            </p>
            <p class="ss_right">
              <span>上传者：{{viewData.createName}}</span>
              <span>上传时间：{{viewData.createTime}}</span>
            </p>
          </div>
          <div class="h_center">
            <div class="cen_left" :style="cc">
              <!-- <div class="r_show" @click="ifShow">
                <span>&gt;&gt;</span>
              </div>-->
              <!-- src="../../../assets/555.mp4" -->
              <video
                :src="infoData.videoList[0].resouceUrl"
                style="background-color:rgb(0,0,0);width:100%;height:100%;display:block"
                controls="controls"
                @click="postTime()"
                autoplay
                @canplay="getVidDur()"
                id="myvideo"
              ></video>
            </div>
            <div class="cen_cneter" @click="ifShow">
              <i v-if="!doshow" class="el-icon-d-arrow-left"></i>
              <i v-else class="el-icon-d-arrow-right"></i>
            </div>
            <div class="cen_right" v-if="doshow">
              <p>课程目录</p>
              <el-row class="tac">
                <!-- <el-col :span="12"> -->
                <el-menu
                  @select="handleSelect"
                  :default-active="defaultindex"
                  class="el-menu-vertical-demo"
                  background-color="#545c64"
                  text-color="#fff"
                  active-text-color="#ffd04b"
                >
                  <el-submenu v-for="item,index in directory" :key="item.id" :index="index+''">
                    <template slot="title">
                      <span>{{item.name}}</span>
                    </template>
                    <el-menu-item-group v-for="items,indexs in item.list" :key="items.id">
                      <el-menu-item :index="index+'-'+indexs+''" @click="cutVideo(items.id,items.name)">
                        <i class="el-icon-video-play" style="line-height: 50px;"></i>
                        <span class="menu_title">{{items.name}}</span>
                        <span>{{items.resourceTime}}</span>
                      </el-menu-item>
                    </el-menu-item-group>
                  </el-submenu>
                </el-menu>
                <!-- </el-col> -->
              </el-row>
            </div>
          </div>

          <div class="h_sss">
            <span>
              <i class="core"></i>
              {{viewData.playCount}}
            </span>
            <span>
              <i class="five_star"></i>
              {{viewData.commentCount}}
            </span>
            <!-- <span>评论数：{{viewData.playCount}}</span> -->
            <!-- <span>播放量：{{viewData.commentCount}}</span> -->
            <span></span>
          </div>
        </div>
      </div>
      <div class="det_main">
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane label="课程资源" name="first">
            <div class="main_info" v-for="item in infoData.docementList" :key="item.id">
              <div class="in_left">
                <p @click="downLoad(item)">
                  <span>{{item.resouceName}}</span>
                  <i class="el-icon-download" style="margin-left:5px"></i>
                </p>
              </div>
              <!-- <div class="in_right">
                <el-button type="primary" size="small" @click="previewFile(item.resouceUrl)">预览</el-button>
                <el-button type="primary" size="small" @click="downLoad(item.resouceUrl)">下载</el-button>
              </div>-->
            </div>
          </el-tab-pane>
          <el-tab-pane label="评论评价" name="second">
            <div class="det_foot" v-if="this.showonw==0||this.showonw==1">
              <el-input type="textarea" :rows="5" placeholder="发表您的看法......" v-model="textarea"></el-input>
              <el-button type="primary" @click="releaseComment" size="mini">发表评论</el-button>
            </div>
            <div class="s_text" v-for="item in evalData" :key="item.id">
              <div class="text_left">
                <div class="member_flex" v-if="item.avager==null">
                  <img src="../../../assets/components/userin.png" alt />
                </div>
                <div class="member_flex" v-else>
                  <img :src="item.avager" alt />
                </div>
                <p class="user_name">{{item.createUser}}</p>
              </div>
              <div class="text_right">
                <div class="r_content">
                  <p>{{item.message}}</p>
                </div>
                <div class="r_time">
                  <span>{{item.createTime}}</span>
                </div>
              </div>
            </div>
            <div class="block" v-if="this.evalData!==null">
              <el-pagination
                @current-change="handleCurrentChange"
                :current-page.sync="currentPage1"
                :page-size="5"
                layout="total, prev, pager, next, jumper"
                :total="total"
                background
              ></el-pagination>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
  </div>
</template>
<script>
// ---
var video = () => {
  var videoTime = document.getElementById("myvideo");
  console.log(videoTime.duration, "获取视频时长"); //获取视频时长
  console.log(videoTime.currentTime, "获取视频当前播放时间"); //获取视频当前播放时间
};
// ---
export default {
  data() {
    return {
      // 面包屑的名字
      breadtitle: null,
      showonw: null,
      showp: null,
      shown: null,
      //
      defaultindex: null,
      //
      viewData: null, //视频信息
      directory: null, //目录
      infoData: null, //课程资料
      evalData: null, //评论信息
      // 视频地址

      doshow: true,
      activeName: "first",
      // 评论
      textarea: null,
      // 课时id
      resourceId: null,
      // 分页
      total: null,
      currentPage1: 1, //页数
    };
  },
  created() {
    console.log(this.$route.query, "id");
    this.showonw = this.$route.query.cardsite;
    this.breadtitle = this.$route.query.names;
    this.defaultindex = this.$route.query.index;
    //
    this.showp = window.sessionStorage.getItem("defaultindex");
    this.shown = window.sessionStorage.getItem("defaultname");
    //
    this.queryCourselist(this.$route.query.id); //课程信息
    this.getClasslogue(this.$route.query.id); //目录
    this.getClasslogue3(this.$route.query.nums); //资源
  },
  mounted() {},
  methods: {
    //---视频
    getVidDur() {
      video();
    },
    //-----获取数据
    async queryCourselist(id) {
      let form = new FormData();
      form.append("courseId", id);
      this.$Http.queryCourseDetailByID(form).then((res) => {
        console.log(res, "获取信息");
        if (res.status == 200) {
          let aData = JSON.parse(JSON.stringify(res.data));
          aData.periodName = aData.periodName.join("/");
          aData.gradeName = aData.gradeName.join("/");
          aData.subjectName = aData.subjectName.join("/");
          this.viewData = aData;
        }
      });
      return;
      let datas = JSON.stringify({
        courseIdList: [id], //课程id集合
        courseType: 1, //课程类型(1-课程，2-资料)
        sortId: 1, //排序ID(1-上传时间，2-播放量，3-评价数量，4-收藏数量，5-点赞数量，6-下载量)
        sortType: 1, //排序类型（1-正序，2-倒序）
      });
      const { data, status } = await this.$Http.queryCourselist(datas);
      if (status === 200) {
        console.log(data, status, "获取课程", data.list[0]);
        // let aData = data.list[0];
        let aData = JSON.parse(JSON.stringify(data.list[0]));
        aData.periodName = aData.periodName.join("/");
        aData.gradeName = aData.gradeName.join("/");
        aData.subjectName = aData.subjectName.join("/");
        this.viewData = aData;
      }
    },
    //获取课程目录和资源资料
    async getClasslogue(id) {
      const { data, status } = await this.$Http.queryCatalogue({
        courseId: id, //课程id
        type: 1, //类型 1-章节 2-课时 3-课时下的资源 4-课程下的资源
      });
      console.log(data, status, "获取课程章节目录111");
      if (status == 200) {
        let aRes = data.catalogueList;
        for (let x = 0; x < aRes.length; x++) {
          const res = await this.$Http.queryCatalogue({
            catalogueId: aRes[x].id,
            courseId: id, //课程id
            type: 2, //类型 1-章节 2-课时 3-课时下的资源 4-课程下的资源
          });
          console.log(res.data, "获取课程课时目录222");
          if (status == 200) {
            aRes[x].list = res.data.catalogueList;
            // let bRes = res.data.catalogueList;
            // for (let y = 0; y < bRes.length; y++) {
            //   this.getClasslogue3(this.$route.query.id, bRes[y].id);
            // }
          }
        }
        this.directory = aRes;
      }
    },
    // 获取资源
    async getClasslogue3(id) {
      const { data, status } = await this.$Http.queryCatalogue({
        catalogueId: id,
        courseId: this.$route.query.id, //课程id
        type: 3, //类型 1-章节 2-课时 3-课时下的资源 4-课程下的资源
      });
      console.log(data, status, "获取视频333");
      if (status == 200) {
        // this.infoData.push(...data.resource.docementList);
        this.infoData = data.resource;
        console.log(this.infoData, "视频", this.infoData);
        this.resourceId = this.infoData.videoList[0].resouceId;
        this.queryCourseComment(this.$route.query.id); //评论
      }
    },

    // 切换课程视频
    cutVideo(id, name) {
      console.log(id, "切换");
      this.getClasslogue3(id);
      this.breadtitle = name;
    },

    // 获取评论
    async queryCourseComment(id) {
      const { data, status } = await this.$Http.queryCourseComment({
        courseId: id - 0, //课程ID
        resourceId: this.resourceId, //资源id
        pageNo: this.currentPage1, // 页码
        pageSize: 5, //每页的数量
      });
      if (status == 200) {
        if (data !== null) {
          console.log(status, data.list, data.total, "评论");
          this.evalData = data.list;
          this.total = data.total;
        }
      }
    },
    // 发布评论
    async releaseComment() {
      console.log(this.resourceId, "this.resourceId");
      const { data, status } = await this.$Http.releaseComment({
        // commentId: commentId, //
        // createUser: createUser, //
        message: this.textarea, //评论信息
        resourceId: this.resourceId, //资源Id
        serviceId: this.$route.query.id, //课程ID
        // type: type, //
      });
      if (status == 200) {
        console.log(status, data, "发布评论");
        this.textarea = null;
        this.queryCourseComment(this.$route.query.id);
      }
    },
    // 预览
    previewFile(url) {
      window.open(`${url}`);
      return;
      window.open(
        "https://view.xdocin.com/view?func=deadline&src=" +
          encodeURIComponent(`${url}`)
      );
    },
    // 下载
    // async downLoad(url) {
    //   console.log(url, "下载", typeof url);
    //   let form = new FormData();
    //   form.append("url", url);
    //   await this.$Http.downLoad(form);
    //   // const { data, status } = await this.$Http.downLoad(form);
    //   // if (status == 200) {
    //   //   console.log(data, status, "匣子");
    //   // }
    // },

    // 下载
    async downLoad(item) {
      console.log("下载", item);
      let pardel = { url: item.resouceUrl };
      this.$axios({
        method: "get",
        url: "./api/file/downLoad",
        params: pardel,
        responseType: "blob",
      }).then(async (res) => {
        console.log(res, "详情下载文件");
        if (!res) {
          this.$message.error("文件获取失败");
          return;
        } else {
          await this.$Http
            .collect({
              resourceId: item.resouceId, //下载资源的id
              serviceId: this.$route.query.id, //业务id
              type: 1, //业务类型 1-课程/资料 2-教研圈
            })
            .then((result) => {
              console.log(result, "下载统计");
            });
        }
        if (res.status == 200) {
          console.log("开始下载");
          let url = window.URL.createObjectURL(new Blob([res.data]));
          let link = document.createElement("a");
          link.style.display = "none";
          link.href = url;
          let fileNames = item.resouceName;
          link.setAttribute("download", fileNames);
          document.body.appendChild(link);
          link.click();
        }
      });

      // await this.$Http.downLoad(formdata).then((res) => {});

      // return;
    },

    //当前激活的菜单
    handleSelect(index, indexpath) {
      console.log(index, indexpath, "当前激活的菜单");
      this.defaultindex = index;
      // this.$router.push({
      //   name: index,
      // });
    },

    // 分页
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.currentPage1 = val;
      this.queryCourseComment(this.$route.query.id);
    },
    // 展开收缩
    ifShow() {
      this.doshow = !this.doshow;
    },
    //
    handleClick(tab, event) {
      console.log(tab, event);
    },
    //video视频时间
    postTime() {
      console.log(this.video, "1232312", video);
      console.log(this.video.duration, "hhhhhhhhh");
      console.log(this.video.currentTime);
      console.log(this.video.onended);
    },
  },
  computed: {
    cc() {
      if (this.doshow) {
        return `width:860px`;
      } else {
        return `width:1184px`;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.details {
  .det_center {
    // width: 1200px;
    min-height: 800px;
    // margin: 0 auto;
    margin-bottom: 20px;
    nav {
      width: 1200px;
      margin: 0 auto;
      padding: 15px 0;
    }
    .det_header {
      padding: 20px;
      background-color: #fff;
      .det_h_center {
        width: 1200px;
        margin: 0 auto;
      }
      .h_top {
        // width: 440px;
        // height: 29px;
        font-size: 22px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #141516;
        // line-height: 26px;
      }
      .h_ss,
      .h_sss {
        margin-top: 15px;
        font-size: 16px;
        font-weight: 500;
        span {
          display: inline-block;
          margin-right: 20px;
          // width: 24px;
          // height: 16px;
          font-size: 12px;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          font-weight: 400;
          color: #5d6066;
          // line-height: 14px;

          i {
            display: inline-block;
            width: 31px;
            height: 31px;
            line-height: 20px;
            vertical-align: middle;
          }
          .core {
            background: url("../../../assets/icon/blackplay.png") no-repeat;
          }
          .five_star {
            background: url("../../../assets/icon/blackcomment.png") no-repeat;
          }
        }
      }
      .h_ss {
        display: flex;
        justify-content: space-between;
        .ss_left {
          span {
            max-width: 91px;
            padding: 0 8px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            // width: 43px;
            // height: 20px;
            border-radius: 4px 4px 4px 4px;
            opacity: 1;
            border: 1px solid #d2d2d2;
            cursor: pointer;
          }
        }
      }

      .h_center {
        display: flex;
        justify-content: space-between;
        margin-top: 15px;
        .cen_left {
          height: 500px;
          background-color: #fff;
          // position: relative;
          // .r_show {
          //   position: absolute;
          //   right: 0;
          //   top: 10px;
          //   width: 20px;
          //   height: 20px;
          //   cursor: pointer;
          //   background-color: #ddddff;
          //   z-index: 998;
          //   // background-position: 0 -640px;
          // }
        }
        .cen_cneter {
          cursor: pointer;
          height: 500px;
          line-height: 500px;
        }
        .cen_right {
          width: 300px;
          min-height: 470px;
          max-height: 470px;
          overflow-y: auto;
          overflow-x: hidden;
          padding: 15px;
          background-color: #545c64;
          .el-menu {
            border: 0;
            .el-menu-item {
              display: flex;
              justify-content: space-between;
              .menu_title {
                width: 150px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
              }
            }
          }
          p {
            width: 269px;
            height: 20px;
            line-height: 20px;
            padding: 15px;
            background-color: #545c64;
            color: #fff;
            font-size: 18px;
          }
        }
      }
    }
    .det_main {
      width: 1200px;
      margin: 0 auto;
      margin-top: 20px;
      padding: 0 20px 10px;
      background-color: #fff;
      .el-tabs {
        ::v-deep .el-tabs__item {
          font-size: 18px;
          height: 50px;
          line-height: 50px;
          font-weight: 600;
        }
      }
      .main_info {
        // height: 32px;
        padding: 10px;
        display: flex;
        justify-content: space-between;
        .in_left {
          p {
            cursor: pointer;
            // width: 128px;
            // height: 21px;
            font-size: 16px;
            font-family: Microsoft YaHei-Regular, Microsoft YaHei;
            font-weight: 400;
            color: #141516;
            // line-height: 19px;
          }
          p:hover {
            color: #409eff;
          }
        }
      }

      .s_text {
        width: 1140px;
        // height: 110px;
        padding: 20px 10px 0;
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid #d7dce4;

        .text_left {
          width: 150px;
          // height: 110px;
          .member_flex {
            display: flex;
            justify-content: center;
          }
          .user_name {
            margin-top: 10px;
            text-align: center;
            // width: 42px;
            // height: 18px;
            font-size: 14px;
            font-family: Microsoft YaHei-Regular, Microsoft YaHei;
            font-weight: 400;
            color: #141516;
            // line-height: 16px;
          }
        }
        .text_right {
          width: 1039px;
          // height: 110px;
          padding: 0 15px;
          .r_content {
            // height: 70px;
            p {
              // width: 98px;
              // height: 18px;
              font-size: 14px;
              font-family: Microsoft YaHei-Regular, Microsoft YaHei;
              font-weight: 400;
              color: #5d6066;
              // line-height: 16px;
              overflow: hidden;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 3000;
            }
          }
          .r_time {
            // height: 10px;
            display: flex;
            justify-content: flex-end;
            // width: 67px;
            // height: 16px;
            font-size: 12px;
            font-family: Microsoft YaHei-Regular, Microsoft YaHei;
            font-weight: 400;
            color: #8d9199;
            // line-height: 14px;
          }
        }
      }
      .block {
        padding: 20px 10px 0;
        display: flex;
        justify-content: center;
      }
      .det_foot {
        margin-top: 20px;
        .el-button {
          margin-top: 10px;
          float: right;
        }
      }
    }
  }

  .cen_right::-webkit-scrollbar {
    width: 0 !important;
  }
}
</style>